// Navbar transparent

.navbar-transparent {
  background-color: $navbar-transparent-bg;
  border-color: $navbar-transparent-border-color;

  .navbar-brand {
    color: $navbar-transparent-brand-color;

    &:hover,
    &:focus {
      color: $navbar-transparent-brand-hover-color;
      background-color: $navbar-transparent-brand-hover-bg;
    }
  }

  .navbar-text {
    color: $navbar-transparent-color;
  }

  .navbar-nav {
    > li {
      > a {
        color: $navbar-transparent-link-color;

        &:hover,
        &:focus {
          color: $navbar-transparent-link-hover-color;
          background-color: $navbar-transparent-link-hover-bg;
        }
      }
    }

    > .active {
      > a {
        &,
        &:hover,
        &:focus {
          color: $navbar-transparent-link-active-color;
          background-color: $navbar-transparent-link-active-bg;
        }
      }
    }
  }
}
