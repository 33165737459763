// Buttons

.btn {
  text-transform: $btn-text-transform;
  letter-spacing: $btn-letter-spacing;
}

.btn-link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.btn-borderless {
  border: 0;
}

.btn-xs {
  @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-xs, $btn-border-radius-sm);
}

.btn-more {
  &::after {
    position: relative;
    top: $btn-more-top;
    display: inline-block;
    padding-left: $btn-more-padding-left;
    color: inherit;
    content: "〉";
  }
}


// Button Radius

.btn-pill {
  padding-right: $btn-pill-padding-x;
  padding-left: $btn-pill-padding-x;
  @include border-radius($border-radius-pill);
}

.btn-square {
  border-radius: 0;
}
