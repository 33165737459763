// Card Link List

.card-link-list {
  a {
    margin-left: $card-link-list-margin-left;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
